import { TILE_SIZES } from '@mixtiles/web-backend-shared'

export type QuestionProps = {
  translateKey: string
  question: string
  translateParams?: Record<string, TILE_SIZES>
}

const COMMON_QUESTIONS: QuestionProps[] = [
  {
    translateKey: 'shipping-time',
    question: 'Is it easy to move the tiles around?',
  },
  {
    translateKey: 'move-tiles',
    question: 'Is it easy to move the tiles around?',
  },
  { translateKey: 'walls', question: "And they won't hurt my walls?" },
  {
    translateKey: 'shipping-location',
    question: 'Do you ship internationally?',
  },
]

export const FAQS: QuestionProps[] = [
  {
    translateKey: 'frames',
    question: 'How big are the tiles?',
    translateParams: {
      v1: TILE_SIZES.SQUARE_8X8,
      v2: TILE_SIZES.RECTANGLE_27X36,
      v3: TILE_SIZES.RECTANGLE_22X44,
    },
  },
  ...COMMON_QUESTIONS,
]

export const ART_FAQS: QuestionProps[] = [
  {
    translateKey: 'frames-art',
    question: 'How big are the tiles?',
    translateParams: {
      v1: TILE_SIZES.RECTANGLE_8X11,
      v2: TILE_SIZES.RECTANGLE_22X44,
    },
  },
  ...COMMON_QUESTIONS,
]
